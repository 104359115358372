import React from "react";
import { useLocation } from "react-router-dom";
import GoHomeButton from "../../Components/GoHomeButton";
import MovieCard from "../../Components/MovieCard";
import { Movie } from "../../Models";
import { fetchMovie } from "../../Services/Api/tmdb";
import classes from "./classes.module.css";

export default function DetailsPage() {
  const location = useLocation();
  const movieFromState: Movie | undefined = location.state?.movie;
  const [movie, setMovie] = React.useState<Movie | undefined>(movieFromState);

  const id = +location.pathname.substring("/details/".length);

  React.useEffect(() => {
    if (!movieFromState) {
      fetchMovie(id).then((res) => {
        setMovie(res);
      });
    }
  }, [id, movieFromState]);

  return (
    <div className={classes.root}>
      <GoHomeButton />
      {!movie && <p>Movie not found !</p>}
      {movie && <MovieCard movie={movie} />}
    </div>
  );
}
