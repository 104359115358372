import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Movie, SearchMoviesResult } from "../../Models";
import classes from "./classes.module.css";

function sortMovies(movies: Movie[], order: Order): Movie[] {
  const sortedMovies = movies.sort((m1, m2) => {
    const { ordering, orderBy } = order;
    if (typeof m1[orderBy] === "string")
      return ordering * String(m1[orderBy]).localeCompare(String(m2[orderBy]));

    if (typeof m1[orderBy] === "number")
      return ordering * (+m1[orderBy] - +m2[orderBy]);

    return 0;
  });

  return [...sortedMovies];
}

type Props = { searchResult: SearchMoviesResult };

type Order = {
  ordering: -1 | 1;
  orderBy: keyof Movie;
};

export default function SearchResult({ searchResult }: Props) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState<Order>();
  const movies = order
    ? sortMovies(searchResult.results, order)
    : searchResult.results;

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className={classes.row}>
            <TableCell
              align="center"
              className={classes.headCell}
              onClick={() => {
                setOrder({
                  ordering: order ? (-order.ordering as Order["ordering"]) : 1,
                  orderBy: "id",
                });
              }}
            >
              ID
            </TableCell>
            <TableCell
              align="center"
              className={classes.headCell}
              onClick={() => {
                setOrder({
                  ordering: order ? (-order.ordering as Order["ordering"]) : 1,
                  orderBy: "title",
                });
              }}
            >
              Titre
            </TableCell>
            <TableCell
              align="center"
              className={classes.headCell}
              onClick={() => {
                setOrder({
                  ordering: order ? (-order.ordering as Order["ordering"]) : 1,
                  orderBy: "vote_average",
                });
              }}
            >
              Évaluation
            </TableCell>
            <TableCell
              align="center"
              className={classes.headCell}
              onClick={() => {
                setOrder({
                  ordering: order ? (-order.ordering as Order["ordering"]) : 1,
                  orderBy: "vote_count",
                });
              }}
            >
              Nb de votes
            </TableCell>
            <TableCell
              align="center"
              className={classes.headCell}
              onClick={() => {
                setOrder({
                  ordering: order ? (-order.ordering as Order["ordering"]) : 1,
                  orderBy: "popularity",
                });
              }}
            >
              Popularité
            </TableCell>
            <TableCell
              align="center"
              className={classes.headCell}
              onClick={() => {
                setOrder({
                  ordering: order ? (-order.ordering as Order["ordering"]) : 1,
                  orderBy: "release_date",
                });
              }}
            >
              Date de sortie
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {movies.map((movie) => (
            <TableRow
              key={movie.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className={classes.row}
              onClick={() => navigate(`details/${movie.id}`, { state: movie })}
            >
              <TableCell align="center">{movie.id}</TableCell>
              <TableCell align="center">{movie.title}</TableCell>
              <TableCell align="center">{movie.vote_average}</TableCell>
              <TableCell align="center">{movie.vote_count}</TableCell>
              <TableCell align="center">{movie.popularity}</TableCell>
              <TableCell align="center">{movie.release_date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
