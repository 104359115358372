import React from "react";
import { fetchMovies } from "../../Services/Api/tmdb";
import SearchBar from "../../Components/SearchBar";
import classes from "./classes.module.css";
import SearchResult from "../../Components/SearchResult";
import { SearchMoviesResult } from "../../Models";
import { SearchTextContext } from "../../Contexts/SearchTextContextProvider";

export type HomePageLocationState = { searchText?: string };

export default function HomePage() {
  const { searchText } = React.useContext(SearchTextContext);
  const [searchResult, setSearchResult] = React.useState<SearchMoviesResult>();

  React.useEffect(() => {
    if (searchText)
      fetchMovies(searchText).then(async (res) => setSearchResult(res));
  }, [searchText]);

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>My movie app</h1>
      <SearchBar />
      {searchResult && <SearchResult searchResult={searchResult} />}
    </div>
  );
}
