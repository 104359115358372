import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import DetailsPage from "./Pages/DetailsPage";
import NoPage from "./Pages/NoPage";
import { StyledEngineProvider } from "@mui/material";
import SearchTextContextProvider from "./Contexts/SearchTextContextProvider";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <SearchTextContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/search" element={<HomePage />} />
            <Route path="/details/:id" element={<DetailsPage />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </BrowserRouter>
      </SearchTextContextProvider>
    </StyledEngineProvider>
  );
}

export default App;
