import { TextField, InputAdornment } from "@mui/material";
import React from "react";
import { SearchTextContext } from "../../Contexts/SearchTextContextProvider";
import SearchIcon from "../SearchIcon";
import classes from "./classes.module.css";

export default function SearchBar() {
  const { setSearchText } = React.useContext(SearchTextContext);
  const [searchBarValue, setSearchBarValue] = React.useState("");

  return (
    <div className={classes.root}>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon onClick={() => setSearchText(searchBarValue)} />
            </InputAdornment>
          ),
        }}
        color="primary"
        onChange={(e) => {
          setSearchBarValue(e.target.value);
        }}
        variant="outlined"
        value={searchBarValue}
        placeholder="Search..."
        size="small"
        onKeyDown={(event) => {
          if (event.code === "Enter") setSearchText(searchBarValue);
        }}
      />
    </div>
  );
}
