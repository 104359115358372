import React from "react";

export const SearchTextContext = React.createContext({
  searchText: "",
  setSearchText: (searchText: string) => {},
});

type Props = {
  children: React.ReactNode;
};

export default function SearchTextContextProvider({ children }: Props) {
  const [searchText, setSearchText] = React.useState("");

  return (
    <SearchTextContext.Provider value={{ searchText, setSearchText }}>
      {children}
    </SearchTextContext.Provider>
  );
}
