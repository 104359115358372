import { Home } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import classes from "./classes.module.css";

export default function GoHomeButton() {
  const navigate = useNavigate();

  return (
    <Button className={classes.root} onClick={() => navigate("/")}>
      <Home />
      Go home
    </Button>
  );
}
