import GoHomeButton from "../../Components/GoHomeButton";
import classes from "./classes.module.css";

export default function NoPage() {
  return (
    <div className={classes.root}>
      <GoHomeButton />
      <p>404 Not found :)</p>
    </div>
  );
}
